import React from "react"

const ShowWebsiteButton = props => {
    return (
        <a href={props.href} target="_blank" className="link-btn-wrap">
            <div className="link-btn">
                <span className="link-btn-text">{props.title}</span>
                <div className="bracket" />
            </div>
        </a>
    )
}
export default ShowWebsiteButton