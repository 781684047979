import React from "react"
import ShowDetailButton from "../showDetailButton";
import ReadMoreButton from "../readMoreButton";

const OperationSupport = props => {
    return (
        <section className="operation-support">
            <div className="section-title-wrap">
                <h2 className="title">コインランドリー運営支援</h2>
                <p className="sub">Support for laundromat operations</p>
            </div>
            <div className="body-wrap">
                <img src="https://stgokulabweb.blob.core.windows.net/images/home/operation-support.png"
                     alt="Owners Support"
                     className="service-image"
                />
                <div className="information-wrap">
                    <h3>ランドリーオーナーズサポート</h3>
                    <p>コールセンター、清掃、メンテナンスなど日々の運営業務を<br className="pc" />OKULABが代行します。</p>
                    <ReadMoreButton href="/services/support" title="詳しくみる" />
                </div>
            </div>
        </section>
    )
}
export default OperationSupport
