import React from "react"
import ShowDetailButton from "../showDetailButton";

const Banner = props => {
    return (
        <section className="banner">
            <div className="banner-list">
                <div className="banner-item">
                    <img src="https://stgokulabweb.blob.core.windows.net/images/home/banner_bukken.jpg"
                         alt="Property Wanted"
                    />
                    <div className="banner-info">
                        <div className="banner-title">物件募集</div>
                        <div className="banner-sub">Property Wanted</div>
                        <ShowDetailButton href="/alliance/property" title="詳しくみる" />
                    </div>
                </div>
                <div className="banner-item">
                    <img src="https://stgokulabweb.blob.core.windows.net/images/home/banner_partner.jpg"
                         alt="Laundry Machine Sales"
                    />
                    <div className="banner-info">
                        <div className="banner-title">ビジネスパートナー<br />募集</div>
                        <div className="banner-sub">Business Partners Wanted</div>
                        <ShowDetailButton href="/alliance/partner" title="詳しくみる" />
                    </div>
                </div>
            </div>

        </section>
    )
}
export default Banner
