import React from "react"
import ReadMoreButton from "../readMoreButton";

const MachineSales = () => {
    return (
        <section className="machine-sales">
            <div className="section-title-wrap">
                <h2 className="title">コインランドリー機器販売</h2>
                <p className="sub">Sales for laundry machines</p>
            </div>
            <p className="lead">ホテル、医療機関など、さまざまな場所への機器設置をサポート</p>
            <div className="image-gallery">
                <img src="https://stgokulabweb.blob.core.windows.net/images/home/machine-sales-01.png"
                     alt="Machine Sales 01"
                />
                <img src="https://stgokulabweb.blob.core.windows.net/images/home/machine-sales-02.png"
                     alt="Machine Sales 02"
                />
            </div>
            <div className="opening-support-footer">
                <ReadMoreButton href="/services/machine" title="詳しくみる" />
            </div>
        </section>
    )
}
export default MachineSales
