import React from "react"
import styled from "styled-components"
import InViewMonitor from "react-inview-monitor"

export const Up = ({ children, delay }) => (
  <InViewMonitor childPropsInView={{ isActive: true }}>
    <FadeInUp isActive={false} delay={delay}>
      {children}
    </FadeInUp>
  </InViewMonitor>
)

const FadeIn = styled.div`
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
  ${({ isActive }) => isActive && `opacity: 1;`}
  ${({ delay }) => delay && `transition-delay: ${delay}ms;`}
`
const FadeInUp = styled(FadeIn)`
  transform: translateY(100px);
  ${({ isActive }) => isActive && `transform: translateY(0px);`}
`
