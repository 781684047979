import React from "react"
import {Link} from "gatsby";
import {Carousel} from "react-responsive-carousel";


const HomeCarousel = props => {
    return (
        <section className="home-carousel">
            <div className="mv-wrap">
                <div id="mv-cover">
                    <div className="message-background">
                        <div className="message">
                            <img
                                src="https://stgokulabweb.blob.core.windows.net/images/home/create_valuable_moment.svg"
                                alt="Create Valuable Moment"
                                loading="lazy"
                            />
                            <Link to="/company/mission" className="our-mission-wrap">
                                <div className="our-mission">
                                  <span className="our-mission-text">
                                    Our Mission
                                  </span>
                                    <div className="bracket" />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div id="mv" className="pc">
                    <Carousel
                        autoPlay={true}
                        infiniteLoop={true}
                        showArrows={false}
                        showStatus={false}
                        showThumbs={false}
                        swipeable={false}
                        animationHandler={'fade'}
                        interval={5000}
                    >
                        <img
                            src="https://stgokulabweb.blob.core.windows.net/images/home/mv/slide-img01.jpg"
                            className="carousel-image"
                            alt="Slide 01"
                        />
                        <img
                            src="https://stgokulabweb.blob.core.windows.net/images/home/mv/slide-img02.jpg"
                            className="carousel-image"
                            alt="Slide 02"
                        />
                        <img
                            src="https://stgokulabweb.blob.core.windows.net/images/home/mv/slide-img03.jpg"
                            className="carousel-image"
                            alt="Slide 03"
                        />
                        <img
                            src="https://stgokulabweb.blob.core.windows.net/images/home/mv/slide-img04.jpg"
                            className="carousel-image"
                            alt="Slide 04"
                        />
                        <img
                            src="https://stgokulabweb.blob.core.windows.net/images/home/mv/slide-img05.jpg"
                            className="carousel-image"
                            alt="Slide 05"
                        />
                    </Carousel>
                </div>

                {/*/#mv*/}
                <div id="mv-sp" className="sp">
                    <Carousel
                        autoPlay={true}
                        infiniteLoop={true}
                        showArrows={false}
                        showStatus={false}
                        showThumbs={false}
                        swipeable={false}
                        showIndicators={false}
                        animationHandler={'fade'}
                        interval={5000}
                    >
                        <img
                            src="https://stgokulabweb.blob.core.windows.net/images/home/mv/sp-slide-img01.jpg"
                            className="carousel-image"
                            alt="Slide 01"
                        />
                        <img
                            src="https://stgokulabweb.blob.core.windows.net/images/home/mv/sp-slide-img02.jpg"
                            className="carousel-image"
                            alt="Slide 02"
                        />
                        <img
                            src="https://stgokulabweb.blob.core.windows.net/images/home/mv/sp-slide-img03.jpg"
                            className="carousel-image"
                            alt="Slide 03"
                        />
                        <img
                            src="https://stgokulabweb.blob.core.windows.net/images/home/mv/sp-slide-img04.jpg"
                            className="carousel-image"
                            alt="Slide 04"
                        />
                        <img
                            src="https://stgokulabweb.blob.core.windows.net/images/home/mv/sp-slide-img05.jpg"
                            className="carousel-image"
                            alt="Slide 05"
                        />
                    </Carousel>
                </div>
            </div>
        </section>
    )
}
export default HomeCarousel
