import React from "react"
import {Link} from "gatsby";

const ReadMoreButton = props => {
    return (
        <div className="read-more-wrap">
            <Link to={props.href} className="read-more-link">
                <div className="read-more-btn">
                    <span className="read-more-text">
                        {props.title}
                    </span>
                    <div className="bracket" />
                </div>
            </Link>
        </div>
    )
}
export default ReadMoreButton