import React from "react"
import { Link } from "gatsby"

const ShowDetailButton = props => {
    return (
        <div className="show-detail-wrap">
            <Link to={props.href} className="show-detail-link">
                <div className="show-detail-btn">
                                <span className="show-detail-text">
                                    {props.title}
                                </span>
                    <div className="bracket" />
                </div>
            </Link>
        </div>
    )
}
export default ShowDetailButton