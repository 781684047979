import React from "react"

const FcLinkButton = props => {
    return (
        <a href="https://fc.okulab.co.jp" target="_blank" className="fc-link-wrap">
            <div className="fc-link-btn">
                    <span className="fc-link-btn-text">
                        FC加盟店<br className="br" />募集中
                    </span>
                <div className="bracket" />
            </div>
        </a>
    )
}
export default FcLinkButton