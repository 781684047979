import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import * as FadeIn from "components/fadein"
import NewsLeads from "components/home/newsLeads";
import OurBrand from "../components/home/ourBrand";
import OpeningSupport from "../components/home/openingSupport";
import OperationSupport from "../components/home/operationSupport";
import Banner from "../components/home/banner";
import HomeCarousel from "../components/home/homeCarousel";
import MachineSales from "../components/home/machineSales";

const IndexPage = ({ data }) => {
  useEffect(() => {
  }, [])

  return (
    <Layout>
      <div>
        <HomeCarousel />
        <div className="contents fw-b">
          <FadeIn.Up>
            <NewsLeads data={data} />
          </FadeIn.Up>
          <FadeIn.Up>
            <OurBrand />
          </FadeIn.Up>
          <FadeIn.Up>
            <OpeningSupport />
          </FadeIn.Up>
          <FadeIn.Up>
            <MachineSales />
          </FadeIn.Up>
          <FadeIn.Up>
            <OperationSupport />
          </FadeIn.Up>
          <FadeIn.Up>
            <Banner />
          </FadeIn.Up>
        </div>
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title=""
    description="OKULAB（オクラボ）は「幸せな洗濯をすべての人に」をミッションとし、コインランドリー「Baluko Laundry Place」のFC事業及び、コインランドリーの出店・運営支援事業を行っています。"
    topFlag={true}
  />
)

export default IndexPage
export const query = graphql`
  query ($limit: Int = 3, $skip: Int = 0) {
    allStrapiOkulabHpPost(
      sort: { fields: [publishedAt], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          strapi_id
          title
          publishedAt
          slug
          childStrapiOkulabHpPostBodyTextnode {
            body
          }
        }
      }
    }
  }
`
