import React from "react"
import ReadMoreButton from "../readMoreButton";

const OpeningSupport = () => {
    return (
        <section className="opening-support">
            <div className="section-title-wrap">
                <h2 className="title">コインランドリー出店支援</h2>
                <p className="sub">Support for opening laundromats</p>
            </div>
            <p className="lead">他ブランドコインランドリー出店の設計・企画・デザインから機器選定・運営までサポート</p>
            <div className="image-gallery">
                <img src="https://stgokulabweb.blob.core.windows.net/images/home/freddy-store01.jpg"
                    alt="Opening Support 01"
                />
                <img src="https://stgokulabweb.blob.core.windows.net/images/home/idobata-store.jpg"
                     alt="Opening Support 02"
                />
                <img src="https://stgokulabweb.blob.core.windows.net/images/home/shirofuwa-store.jpg"
                     alt="Opening Support 03"
                />
                <img src="https://stgokulabweb.blob.core.windows.net/images/home/freddy-store02.jpg"
                     alt="Opening Support 04"
                />
            </div>
            <div className="opening-support-footer">
                <ReadMoreButton href="/services/startup" title="詳しくみる" />
            </div>
        </section>
    )
}
export default OpeningSupport
