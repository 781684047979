import React from "react"
import ShowWebsiteButton from "../showWebsiteButton";
import FcLinkButton from "../fcLinkButton";

const OurBrand = props => {
    return (
        <section className="our-brand">
            <div className="base">
                <div className="pattern">
                    <div className="section-title-wrap">
                        <h2 className="title">運営事業</h2>
                        <p className="sub">Our Brands</p>
                    </div>
                    <div className="baluko-service">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/home/our_brand_01.jpg"
                             alt="Baluko Image"
                             className="baluko-image"/>
                        <div className="left-area">
                            <img src="https://stgokulabweb.blob.core.windows.net/images/home/baluko_logo.svg"
                                 className="baluko-logo"
                                 alt="Baluko Logo" />
                            <p className="baluko-subtitle">新しいライフスタイルを提案する<br />コインランドリー</p>
                            <ShowWebsiteButton href="https://baluko.jp" title="ウェブサイト" />
                            <div className="fc-btn-wrap">
                                <FcLinkButton />
                            </div>
                        </div>
                        <div className="right-area">
                            <img src="https://stgokulabweb.blob.core.windows.net/images/home/our_brand_01.jpg"
                                 alt="Baluko Image"
                                className="baluko-image"/>
                            <div className="link-gallery">
                                <a href="https://app.baluko.jp" target="_blank" className="link-item">
                                    <img src="https://stgokulabweb.blob.core.windows.net/images/home/our_brand_02.jpg"
                                         alt="App Image"
                                         className="link-image"/>
                                    <span className="link-title">
                                        バルコアプリ
                                        <img src="https://stgokulabweb.blob.core.windows.net/images/home/bracket_white_normal.svg"
                                             className="service-bracket"
                                             alt="Bracket"/>
                                    </span>
                                </a>
                                <a href="https://journal.baluko.jp" target="_blank" className="link-item">
                                    <img src="https://stgokulabweb.blob.core.windows.net/images/home/our_brand_03.jpg"
                                         alt="Journal Image"
                                         className="link-image"/>
                                    <span className="link-title">
                                        ジャーナル
                                        <img src="https://stgokulabweb.blob.core.windows.net/images/home/bracket_white_normal.svg"
                                             className="service-bracket"
                                             alt="Bracket"/>
                                    </span>
                                </a>
                                <a href="https://shop.baluko.jp" target="_blank" className="link-item">
                                    <img src="https://stgokulabweb.blob.core.windows.net/images/home/our_brand_04.jpg"
                                         alt="Shop Image"
                                         className="link-image"/>
                                    <span className="link-title">
                                        オンラインショップ
                                        <img src="https://stgokulabweb.blob.core.windows.net/images/home/bracket_white_normal.svg"
                                             className="service-bracket"
                                             alt="Bracket"/>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="fc-btn-wrap">
                            <FcLinkButton />
                        </div>
                    </div>
                    <div className="other-service">
                        <div className="service">
                            <div className="service-info">
                                <p className="service-title">
                                    <p className="sub-title">金沢発　手作りドーナツ</p>
                                    どうなつ日和
                                </p>
                                <img src="https://stgokulabweb.blob.core.windows.net/images/home/dounut_logo.svg"
                                     alt="Donut Logo"
                                    className="service-logo"/>
                                <ShowWebsiteButton href="https://donut-biyori.jp" title="ウェブサイト" />
                            </div>
                            <img src="https://stgokulabweb.blob.core.windows.net/images/home/dounut.jpg"
                                 alt="Donut Image"
                                className="service-image"/>
                        </div>
                        <div className="service">
                            <div className="service-info">
                                <p className="service-title">
                                    <p className="sub-title">洗濯代行プラットフォーム</p>
                                    ランドリーアウト
                                </p>
                                <img src="https://stgokulabweb.blob.core.windows.net/images/home/LO_logo.png"
                                     alt="Laundry OUT Logo"
                                     className="service-logo"/>
                                <ShowWebsiteButton href="https://laundry-out.jp" title="ウェブサイト" />
                            </div>
                            <img src="https://stgokulabweb.blob.core.windows.net/images/home/LO.jpg"
                                 alt="Laundry OUT"
                                 className="service-image"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default OurBrand
