import React from "react"
import {Link} from "gatsby";
import moment from "moment";
import ReadMoreButton from "../readMoreButton";

const NewsLeads = props => {
    return (
        <section className="information">
            <div className="section-title-wrap">
                <h2 className="title">ニュース</h2>
                <p className="sub">News</p>
            </div>
            <div className="news">
                <ul className="news-list">
                    {props.data.allStrapiOkulabHpPost.edges.map(({ node }) => (
                        <li key={node.slug} className="list-item">
                            <Link
                                to={`/news/${node.slug}`}
                                className="news-item"
                            >
                                <p className="published-date">
                                    {moment(node.publishedAt).format(`YYYY.MM.DD`)}
                                </p>
                                <p className="news-title">{node.title}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="news-leads-footer">
                <ReadMoreButton href="/news" title="続きを読む" />
            </div>
        </section>
    )
}
export default NewsLeads
